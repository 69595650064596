@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700;800&display=swap");
* {
  font-family: "Readex Pro", sans-serif;
}

:root {
  --header-height: 66px
}


/* --- listing --- */

.details {
  max-width: 35em;
  margin: 0 auto;
  padding: 2em;
}

.desc {
  text-align: initial;
}

dl {
  margin-top: 0.5em;
}
dt {
  font-weight: bold;
  margin-right: 0.5em;
  display: inline;
}

dd {
  margin: 0;
  display: inline;
}

dt::after {
  content: ": ";
}

dd:after {
  content: "";
  display: block;
  margin-top: 0.25em;
}

.header {
  position: sticky;
  height: var(--header-height);
  z-index:999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1em;
  background-color: hsl(0, 0%, 99%);
  border-bottom: 1px solid rgba(0,0,0,0.5);

  padding: 0 5vw;
}

/* --- home --- */

.search-form {
  width: 75%;
  height:44px;
  max-width: 420px;
  display: flex;
  /* margin: 0 auto 32px; */
  /* margin: 14px 24px 0 24px; */

}

.main-nav__add {
  width:100%;
  height:100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  font-size: 1em;
  width: 100%;
  background-color: #edeff0;
  padding: 10px 15px;
  border: 3px solid #d7dbdf;
  border-right: none;
  border-radius: 0.35em 0 0 0.35em;
  outline: none;
}

.main-nav {
    height: 44px;
    aspect-ratio: 1;
  display: flex;
  background-color: #051c33;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
}

.with_loc {
  text-decoration: underline dotted;
}

/* --- leaflet --- */
.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  /* margin: 30px auto; */
  /* box-shadow: 0 0 40px 10px rgb(3 169 244 / 20%); */
  /* border: 2px solid #051c33; */
}

.leaflet-top {
  top: var(--header-height) !important;
}

.location {
  /* background-color: red; */
  display:flex;
  
}

/* coordinates */
.location button {
  padding: 15px 30px;
  margin: 10px 0;
  font-size: 15px;
  border: solid 2px rgb(219, 219, 219);
  border-radius: 10px;
  width: 80%;  outline: none;
  padding: 0px 15px;
  /* border-radius: 0 0.35em 0.35em 0; */
  cursor: pointer;
}

.description {
  max-height: 600px;
  height: 100%;
  background-color: hsla(0, 0%, 100%, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 1px solid grey;
  text-align: left;
  overflow-y: auto;
  scrollbar-width: thin;
}

ol, ul {
	list-style: none;
  padding: 0 0;
}


.leaflet-bottom.leaflet-left {
  max-height: 600px;
}

.empty {
  height: 0;
}

.description a {
  color: black;
}

/* search */

.wrapper {
  position: relative;
}

.info {
  position: absolute;
  z-index: 999;
  max-height: calc(100vh - var(--header-height) - 20px);
  top: calc(var(--header-height) + 10px);
  left: 10px;
  background-color: hsla(0, 0%, 100%, 0.5);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 1px solid grey;
  text-align: left;
  overflow-y: auto;
  scrollbar-width: thin;


  font-size: 12px;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none;

  font-size: 12px;
}


.info li {
  padding: 0 10px;
}

.info span {
  cursor: pointer;
}

.container-map {
  position: fixed;
	bottom:0;
	left:0;
	right:0;
  width: 100%;
  height: 100%;
}

.selected {
  filter: grayscale(100%);
}

a, a:visited {
  text-decoration: none;
  color:unset; 
}


/* header */

.header__logo {
  color: rgb(208,0,0) !important;
  font-size: 2em;
  font-weight: 700;
}

.header__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 1em;
    width: 100%;
}

.search-button {
  border: none;
  outline: none;
  border-top-right-radius: 10%;
  border-bottom-right-radius: 10%;
  background-color: #555;
  cursor: pointer;
}

.search-button__found {
  background-color: hsl(120, 50%, 25%);
}

.search-button__not_found {
  background-color: red;
}

.search-button svg {
  transform: scale(calc(24/32));
}